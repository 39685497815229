<div class="cover">
  <div class="static-slider-head" style="background-image: url(assets/images/innerpage/naturee.jpg); position: relative; height: 60vh; margin-bottom: 20px; ">
    <div class="container" style="position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); width: 100%; ">
      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
          <h1 class="titlecontact">CONTACT</h1>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="maincontainer" style="margin-left: 0px!important; font-family: Kanit, sans-serif ">
  
  <div class="row justify-content-center text-center">
    <div class="col-md-3" style="border: 1px solid #bbb; padding: 10px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); margin: 5px;">
      <i class="fas fa-map-marker-alt"></i>
      <h4 class="mb-3 text-black"  style="font-family: Kanit, sans-serif; margin-top: 20px">Our location</h4>

      <h4 class="mb-3 text-black; margin-top: 20px">Sjeverni Logor, Mostar 88000, Bosnia & Herzegovina</h4>
    </div>
    <div class="col-md-3" style="border: 1px solid #bbb; padding: 10px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); margin: 5px;">
      <i class="fas fa-phone"></i>
      <h4 class="mb-3 text-black"  style="font-family: Kanit, sans-serif; margin-top: 20px">Call us</h4>

      <h4 class="mb-3 text-black"  style="font-family: Kanit, sans-serif; margin-top: 20px">+387 61 808 564</h4>
    </div>
    <div class="col-md-3" style="border: 1px solid #bbb; padding: 10px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); margin: 5px;">
      <i class="fas fa-envelope"></i>
      <h4 class="mb-3 text-black"  style="font-family: Kanit, sans-serif; margin-top: 20px">Our email</h4>
      <h4 class="mb-3 text-black"  style="font-family: Kanit, sans-serif; word-wrap: break-word; margin-top: 20px;">campingneretva@gmail.com</h4>
    </div>
</div>
  <div class="row2" >
    <div class="col-md-6 col-sm-12 text-md-left" style="font-family: Kanit, sans-serif; margin-top: 30px;" >
           
     <div class="message">
        <form action="" class="sign-in-form" method="POST">
          <h2 class="title2" style="font-family: Kanit, sans-serif; text-align: center; margin-bottom: 30px;">Contact us</h2>
          <div class="input-field row">
            <div class="col-md-6">
              <label for="name">Name:</label>
              <input
                type="text"
                required=""
                value=""
                name="Name"
                [(ngModel)]="emailForm.formData.Name"
                #name="ngModel"
                [ngModelOptions]="{standalone: true}"
                id="name"
                class="ng-touched"
              />
              <div *ngIf="name?.invalid && (name?.dirty || name?.touched)">
                <div *ngIf="name?.errors?.required" class="error-message">Enter your name</div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="email">Email:</label>
              <input
                type="text"
                required
                name="Email"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                [(ngModel)]="emailForm.formData.Email"
                #email="ngModel"
                [ngModelOptions]="{standalone: true}"
                id="email"
                class="ng-touched"
              />
              <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                <div *ngIf="email?.errors?.required" class="error-message">Enter your email</div>
                <div *ngIf="email?.errors?.pattern" class="error-message">Enter a valid email</div>
              </div>
            </div>
          </div>
          <div class="input-message">
            <label for="message" style="margin-top: 30px;">Message:</label>
            <textarea id="message" name="message" 
            [(ngModel)]="emailForm.formData.Message"
             class="custom-textarea"
             #message="ngModel"
             required
             >
            </textarea>
            <div *ngIf="message?.invalid && (message?.dirty || message?.touched)">
              <div *ngIf="message?.errors?.required" class="error-message">Enter your message</div>
            </div>
          </div>
          <input type="submit" value="SUBMIT" class="btn" (click)="sendMessage()" [disabled]="isSubmitDisabled()">
          <div *ngIf="(name?.invalid && (name?.dirty || name?.touched)) || (email?.invalid && (email?.dirty || email?.touched) || (message?.invalid && (message?.dirty || message?.touched)))">
            <div *ngIf="(name?.errors?.required) || (email?.errors?.required) || (message?.errors?.required)" class="error-message">
                Please fill in the required fields</div>
          </div>
        </form>
      </div>
      
    </div>

    <div class="col-md-6 col-sm-12 text-md-right">
      <div style="width: 100%; height: 400px;">
        <iframe width="760" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&height=400&hl=en&q=Camping%20Neretva,%20Sjeverni%20Logor,%20Mostar%2088000%20Mostar+(Camping%20Neretva)&t=&z=15&ie=UTF8&iwloc=B&output=embed"></iframe><script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=398689696008c86e243351b38754c436193ea9b1'></script>      
    </div>
  </div>
</div>