import { Component, Input, OnInit } from '@angular/core';
import { 
  animate, 
  style, 
  transition,
  trigger,
  AnimationEvent
} from '@angular/animations';

interface Item {
  src: string; // Renamed for general use
  alt: string;
  type: 'image' | 'video'; // Added type property
}

@Component({
  selector: 'app-rafting',
  templateUrl: './rafting.component.html',
  styleUrls: ['./rafting.component.css'],
  animations: [
    trigger('animation', [
      transition('void => visible', [
        style({transform: 'scale(0.5)'}),
        animate('150ms', style({transform: 'scale(1)'}))
      ]),
      transition('visible => void', [
        style({transform: 'scale(1)'}),
        animate('150ms', style({transform: 'scale(0.5)'}))
      ]),
    ]),
    trigger('animation2', [
      transition(':leave', [
        style({opacity: 1}),
        animate('50ms', style({opacity: 0.8}))
      ])
    ])
  ]
})
export class RaftingComponent implements OnInit {
  @Input() galleryData: Item[] = [];
  @Input() showCount = false;

  previewImage = false;
  showMask = false;
  previewVideo = false; // Added for video preview
  currentLightboxItem: Item = this.galleryData[0];
  currentIndex = 0;
  controls = true;
  totalImageCount = 0;

  constructor() { }

  ngOnInit(): void {
    this.totalImageCount = this.galleryData.length;
  }

  onPreviewImage(index: number): void {
    this.showMask = true;
    const item = this.galleryData[index];
    this.previewImage = item.type === 'image';
    this.previewVideo = item.type === 'video';
    this.currentIndex = index;
    this.currentLightboxItem = item;
  }

  onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.showMask = false;
    }
  }

  onClosePreview() {
    this.previewImage = false;
    this.previewVideo = false;
  }

  next(): void {
    this.currentIndex = (this.currentIndex + 1) % this.galleryData.length;
    this.currentLightboxItem = this.galleryData[this.currentIndex];
  }

  prev(): void {
    this.currentIndex = (this.currentIndex - 1 + this.galleryData.length) % this.galleryData.length;
    this.currentLightboxItem = this.galleryData[this.currentIndex];
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
