import { Component, OnInit } from '@angular/core';

interface Item {
  type: 'image' | 'video';
  src: string;
  alt: string;
}


@Component({
  selector: 'app-rafting-picture',
  templateUrl: './rafting-picture.component.html',
  styleUrls: ['./rafting-picture.component.css']
})


export class RaftingPictureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  data: Item[] = [
    {
      type: 'image',
      src: 'assets/images/innerpage/raftprice.JPG',
      alt: '0'
    },
    {
      type: 'video',
      src: 'assets/images/innerpage/raftvideo.mp4',
      alt: '88'
    },
    {
      type: 'image',
      src: 'assets/images/innerpage/raft6.webp',
      alt: '1'
    },
    {
      type: 'image',
      src: 'assets/images/innerpage/raft3.webp',
      alt: '2'
    },
    
    {
      type: 'image',
      src: 'assets/images/innerpage/raft5.webp',
      alt: '3'
    },
    {
      type: 'image',
      src: 'assets/images/innerpage/raft7.webp',
      alt: '3'
    },
    {
      type: 'image',
      src: 'assets/images/innerpage/raft8.webp',
      alt: '3'
    }
  ]
}