<div class="main">
    <div class="static-slider-head" style="background-image: url(assets/images/landingpage/fire.webp); position: relative; height: 100vh; background-size: cover; background-position: center;">
      <!-- Postavljanje visine slike na 100% i omogućavanje da se slika proteže na cijeli ekran -->
      <div class="container" style="position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); width: 100%;">
        <!-- Stilovi za apsolutno pozicioniranje i centraliziranje -->
        <!-- Row  -->
        <div class="row justify-content-center">
          <!-- Column -->
          <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
            <h3 class="title">MESSAGE HAS BEEN SENT SUCCESSFULLY</h3>
            <p class="subtitle">for response, please wait for the administrator to contact you</p>
          </div>
          <!-- Column -->
        </div>
      </div>
    </div>
  </div>
  