import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-succesful-message',
  templateUrl: './succesful-message.component.html',
  styleUrls: ['./succesful-message.component.css']
})
export class SuccesfulMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
